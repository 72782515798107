import { useSpring, animated } from '@react-spring/web';

function HideButton({onClick, isFlipped})
{
    const style = useSpring({
        transform: isFlipped ? 'scaleX(-1)' : 'scaleX(1)'
    })

    return <div className="hideButton bulletButton" onClick={onClick}>
        <animated.img src={'/media/images/icons/rightArrowIcon.png'} alt={'icon'}  style={style}/>
    </div>
}

export default HideButton