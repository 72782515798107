import { useTransition, animated, config } from '@react-spring/web';

function ProjectDescription({index, title, descriptionText, isActive, containerRect, lessThan1000})
{
    const transition = useTransition(isActive ? [{ key: index }] : [],
        {
            key: item => item.key,
            config: (_, __, state) => 
            {
                switch (state)
                {
                    default: return config.default;
                }
            },

            from: lessThan1000 ? { bottom: -containerRect.height, opacity: '0'} : { right: -containerRect.width, opacity: '-3'} ,
            enter: { right: 0, bottom: 0, opacity: '1'},
            leave: lessThan1000 ? { bottom: -containerRect.height, opacity: '0'} : { right: -containerRect.width, opacity: '-3'},
        });
    const style2 =
    {
        height: containerRect.height
    }

    const paragraphs = descriptionText.map((item,index) => <p key={index}>{item}</p>);  
    

    return transition((style) =>
    <animated.div className="projectDescriptionContainer" style={{...style, ...style2}}>
        <div className="projectDescriptionContent">
            <h1>{title}</h1>
            {paragraphs}
        </div>
    </animated.div>)
}

export default ProjectDescription