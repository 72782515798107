import { useEffect } from "react"

function useResizeObserver(callback, refs)
{
    useEffect(() =>
    {
        const resizeObserver = new ResizeObserver((entries) => callback(entries));

        refs.forEach((ref) => {
            if (ref) {
              resizeObserver.observe(ref);
            }
        });

        return () => resizeObserver.disconnect();
    }, [callback, refs])
}

export default useResizeObserver;