function VerticalDescriptionButton({flipped, onClick})
{
    return <div className="verticalDescriptionButton">
        <div className="content" onClick={onClick}>
            <svg viewBox="0 0 100 10" preserveAspectRatio="xMidYMin slice" strokeWidth=".5" style={{transform: flipped ? "scale(1, -1)" : ""}}>
                <path d="M 0 10 L 49 1.15  Q 50 1 51 1.15 L 100 10" fill="none"/>
            </svg>
            <h2 style={{bottom: flipped ? "1.5rem" : ""}}>{flipped ? "Hide Description" : "Show Description"}</h2>
        </div>
    </div>
}

export default VerticalDescriptionButton