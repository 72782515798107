function Skill({name, logo, experience, animationDelay, onLoad})
{
    return <div className="skill" style={{animationDelay: `${animationDelay}ms`}}>
        <img src={`/media/images/logos/${logo}`} alt={name} onLoad={onLoad} />
        <div>
            <h2>{name}</h2>
            <h3>{`${experience}+ years`}</h3>
        </div>
    </div>
}

export default Skill