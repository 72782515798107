import { useTransition, animated } from '@react-spring/web';
import ProjectDescription from './project_description/ProjectDescription';
import projectData from './projects.json';
import { useState } from 'react';
import BulletButtonContainer from './bullet_buttons/BulletButtonContainer';
import VerticalDescriptionButton from './VerticalDescriptionButton';

function SelectedProject({index, projectRef, containerRect, onClosed, lessThan1000})
{
    const [isActive, setActive] = useState(true);
    const [isDescriptionHidden, setDescriptionHidden] = useState(lessThan1000);
    const projectRect = projectRef.current.getBoundingClientRect();
    const imageRef = projectData[index].imageRef;
    const videoRef = projectData[index].videoRef;
    const title = projectData[index].title;
    const description = projectData[index].description;
    
    const transition = useTransition(isActive ? [{ key: index }] : [],
    {
        key: item => item.index,
        from: { left: projectRect.left, top: projectRect.top, width: `${projectRect.width / containerRect.width * 100}%`, height: `${projectRect.height / containerRect.height * 100}%`},
        enter: { left: 0, top: 0, width: '100%', height: '100%', opacity: 5},
        leave: { left: projectRect.left, top: projectRect.top, width: `${projectRect.width / containerRect.width * 100}%`, height: `${projectRect.height / containerRect.height * 100}%`, opacity:0},
        onRest: () =>
        {
            if (!isActive) onClosed(index);
        }
    });  

    const onHideClick = () => {
        setDescriptionHidden((prev) => !prev);
      };

    return <div>
        <div className='descriptionWrapper'>
            {isActive && (<BulletButtonContainer onCloseClick={() => setActive(false)} onHideClick={onHideClick} isDescriptionHidden={isDescriptionHidden}/>)}
            <ProjectDescription index={index} title={title} descriptionText={description} isActive={isActive && !isDescriptionHidden} containerRect={containerRect} lessThan1000={lessThan1000}/>
            {isActive && <VerticalDescriptionButton flipped={!isDescriptionHidden} onClick={onHideClick}/>}
        </div>
        {transition((style) =>
                <animated.div className='selectedProject' style={style}>
                <video autoPlay muted loop poster={imageRef}>
                    <source src={`/getContent/${videoRef}`} type="video/mp4"/>
                </video>
                </animated.div>)}
    </div>
}

export default SelectedProject