import HamburgerButton from './HamburgerButton.js';
import './Header.css';
import HeaderElement from './HeaderElement.js';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function Header()
{
    let [visible, setVisible] = useState(false);

    function toggleVisible()
    {
        setVisible((previous) => !previous);
    }

    return (
        <div className='header'>
            <div className='title'>
                <Link className="homeButton" to={"/"}>
                    <h1>Tirso Meijer</h1>
                </Link>
                <HamburgerButton onClick={toggleVisible}/>
            </div>
            <HeaderElement name='Projects' link="/projects" isVisible={visible} activeOn={["/projects"]} toggleVisible={toggleVisible}/>
            <HeaderElement name='About' link="/about" isVisible={visible} activeOn={["/about"]} toggleVisible={toggleVisible}/>
            <HeaderElement name='Contact' link="/contact" isVisible={visible} activeOn={["/contact", "/contact-thanks"]} toggleVisible={toggleVisible}/>
        </div>
      );
}

export default Header