import { useRef } from "react"

function Project({title, imageRef, onClick, animationDelay, onLoad, loaded})
{
    const ref = useRef();

    return (
        <div className={'project'} ref={ref} onClick={() => onClick(ref)} style={{display: loaded ? "" : "none", animationDelay: animationDelay}}>
            <div className='overlay'></div>
            <img src={imageRef} alt={title} onLoad={() => onLoad()}/>
            <div className='titlebar'><h2>{title}</h2></div>
        </div>
    )
}

export default Project