import { Link, useLocation } from "react-router-dom"

function HeaderElement({name, link, isVisible, activeOn, toggleVisible})
{
    const location = useLocation();

    return (
        <Link to={link} className={isVisible ? "headerElement visible" : "headerElement"} onClick={toggleVisible}>
            <h2 className={activeOn.some((x) => x === location.pathname) ? "active" : ""}>{name}</h2>
            <div className="overlay"></div>
        </Link>
    )
}

export default HeaderElement