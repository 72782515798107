import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './header/Header.js';
import Projects from './projects/Projects.js';
import Contact from './contact/Contact.js';
import NotFound from './not-found/NotFound.js';
import ContactThanks from './contact/ContactThanks.js';
import About from './about/About.js';
import Landing from './landing/Landing.js';
import { useState } from 'react';

function App() {
  const [showIntro, setShowIntro] = useState(true);

  return (
      <div className="App">
        <Header/>
        <Routes>
          <Route path = "*" element={<NotFound/>}/>
          <Route path = "" element={<Landing showIntro={showIntro} setShowIntro={setShowIntro}/>}/>
          <Route path = "/projects" element={<Projects/>}/>
          <Route path = "/about" element={<About/>}/>
          <Route path = "/contact" element={<Contact/>}/>
          <Route path = "/contact-thanks" element={<ContactThanks/>}/>
        </Routes>
      </div>
  );
}

export default App;
