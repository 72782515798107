import './Contact.css';
import { useState } from 'react';

function Contact()
{
    const [message, setMessage] = useState("");


    function updateMessage(event)
    {
        if (event.target.innerHTML === "<br>") setMessage('');
        else setMessage(event.target.innerText);
    }

    return <div className="contact">
            <div className="contactFormContainer">
                <div className="text">
                    <h1>Contact</h1>
                    <p>If you'd like to get in touch with me, you can send me an email using the form below.</p>
                </div>
                <form action="https://api.web3forms.com/submit" className="contactForm" method="POST">
                    <input type="hidden" name="access_key" value="7f3c2b3f-3fb2-4234-bee6-38912e5a12e2"/>
                    <input type="hidden" name="redirect" value="https://tirsomeijer.com/contact-thanks"/>             
                    <div className="formItem">
                        <label htmlFor="email"><h2>Email</h2></label>
                        <input type="email" id="email" name="email" placeholder='Your email'required/>
                    </div>
                    <div className="formItem">
                        <label htmlFor="subject"><h2>Subject</h2></label>
                        <input type="text" id="subject" name="subject" placeholder='Your subject' required/>
                    </div>
                    <div className="formItem"> 
                        <label htmlFor="message"><h2>Message</h2></label>
                        <textarea id="message" name="message" value={message} readOnly/>
                        <span contentEditable="true" onInput={updateMessage} value={message}/>
                    </div>
                    <input type="checkbox" name="botcheck" className="hidden" style={{display: 'none'}}/>
                    <button type="submit">Submit</button>
                </form>  
            </div>
    </div>
}

export default Contact