import jobExperience from './JobExperience.json'
import Timeline from "./Timeline";

function JobExperience()
{
    return <div className="jobExperience">
                <h2>Employment History</h2>
                <Timeline data={jobExperience}/>
            </div>
}

export default JobExperience;