import Project from './Project.js';
import SelectedProject from './SelectedProject.js';
import './Projects.css';
import projectData from './projects.json'
import { useCallback, useRef, useState} from 'react';
import useResizeObserver from '../hooks/useResizeObserver.js';
import { useMediaPredicate } from 'react-media-hook';

function Projects()
{
    const containerRef = useRef([]);
    const [loaded, setLoaded] = useState(false);
    const [containerRect, setContainerRect] = useState();
    const [selectedProjectRef, setSelectedProjectRef] = useState();
    const [selectedProjectIndex, setSelectedProjectIndex] = useState(-1);
    const lessThan1000 = useMediaPredicate("(max-width: 1000px) and (orientation:portrait)")
    
    const updateContainer = useCallback((entries) => {
        setContainerRect(entries[0].target.getBoundingClientRect())
    }, []);

    useResizeObserver(updateContainer, containerRef.current);

    let loadedImages = 0;
    function OnLoadImage()
    {
        loadedImages++;
        if (loadedImages === projects.length) setLoaded(true);
    }

    const projects = projectData.map((item, index) => 
        <Project 
            key={index} 
            title={item.title} 
            imageRef={item.imageRef} 
            onClick={(ref) => OnProjectClick(index, ref)} 
            animationDelay={`${(index + 1) * 50}ms`}
            onLoad={OnLoadImage}
            loaded={loaded}
        />
    );  

    function OnProjectClick(index, ref)
    {
        setSelectedProjectRef(ref);
        setSelectedProjectIndex(index);
    }
    
    function OnProjectClosed(index)
    {
        setSelectedProjectIndex(prevIndex => {
            if (prevIndex === index)
            {
                return -1;
            }
            else
            {
                return prevIndex;
            }
        });
    }
    
    return (
        <div className={selectedProjectIndex === -1 ? 'projectsContainer' : 'projectsContainer noscroll'} ref={(element) => containerRef.current[0] = element}>
            <div className='projects'>
                {projects}
            </div>
            {selectedProjectIndex !== -1 &&
            (<SelectedProject  
                key={selectedProjectIndex} 
                index={selectedProjectIndex}
                title={projectData[selectedProjectIndex].title} 
                description={projectData[selectedProjectIndex].description}
                onClosed={() => OnProjectClosed(selectedProjectIndex)}
                projectRef={selectedProjectRef}
                containerRect={containerRect}
                lessThan1000={lessThan1000}
            />)}
        </div>
    )
}

export default Projects