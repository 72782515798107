import "./Landing.css";
import PageLink from "./PageLink";

function Landing({showIntro, setShowIntro})
{   
    return <div className="landingContainer">
        <div className="landing">
            <div className="intro" style={showIntro ? {opacity: "1"} : {opacity: "0", pointerEvents: "none"}} onClick={() => setShowIntro(false)}>
                <div className="textWrapper">
                    <div className="textContent">
                        <h2>Hi there!</h2>
                        <p>I'm Tirso Meijer, a game developer based in the Netherlands. Feel free to take a look around!</p>
                        <br/>
                        <p>(Click anywhere to proceed)</p>
                    </div>
                </div>
            </div>
            <div className="pageLinks">
                <div className="overlay" style={{backgroundColor: showIntro ? "var(--color-transparent-dark)" : ""}}/>
                <PageLink to="/projects" title="Projects" description="Take a look at some of my work!" imageSource="/media/images/spacenite.webp" animationDelay="50ms" showIntro={showIntro}/>
                <PageLink to="/about" title="About Me" description="Check out my resume!" imageSource="/media/images/landing/about.webp" animationDelay="100ms" showIntro={showIntro}/>
                <PageLink to="/contact" title="Contact" description="Get in touch!" imageSource="/media/images/landing/contact.webp" animationDelay="150ms" showIntro={showIntro}/>
            </div>
        </div>
    </div>
}

export default Landing

