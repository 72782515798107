import education from './Education.json'
import Timeline from "./Timeline";

function Education()
{
    return <div className="jobExperience">
                <h2>Education</h2>
                <Timeline data={education}/>
            </div>
}

export default Education;