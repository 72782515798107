import CloseButton from "./CloseButton"
import HideButton from "./HideButton"

function BulletButtonContainer({onCloseClick, onHideClick, isDescriptionHidden})
{
    return <div className="bulletButtonContainer">
        <div className="animationWrapper">
            <CloseButton onClick={onCloseClick}/>
            <HideButton onClick={onHideClick} isFlipped={isDescriptionHidden}/>
        </div>
    </div>
}

export default BulletButtonContainer