import { Link } from "react-router-dom"

function PageLink({to, title, description, imageSource, animationDelay, showIntro})
{
    return  <Link to={to} className="pageLink">
        <div className="content">
            <div className="textContent" style={{display: showIntro ? "none" : ""}}>
                <div className="animationWrapper" style={{animationDelay: animationDelay}}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
        </div>
        <div className="imageWrapper">
            <img src={imageSource} alt="title"/>
            <div className="highlight"/>
        </div>
    </Link>
}

export default PageLink