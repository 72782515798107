import { useSpring, animated, easings } from "@react-spring/web";
import { useRef, useState } from "react";
import useResizeObserver from "../hooks/useResizeObserver";

function Timeline({data})
{    
    const textRefs = useRef([]);    
    const nodesSVGRef = useRef();
    const lineSVGRef = useRef();

    const [currentNode, setCurrentNode] = useState(1);
    const [animationData, setAnimationData] = useState([]);
    const [, updateState] = useState();

    // resizing
    useResizeObserver(updateState, textRefs.current);

    // animation
    const [{lineY}] = useSpring(
        () => ({
            from: { lineY: 95 },
            to: { lineY: 1.5 },
            config: 
            {        
                duration: 1000,
                easing: easings.easeInOutCubic
            },
            onChange: (props) => UpdateLine(props)
        }),
        [currentNode]
    )

    function UpdateLine({value})
    {
        const index = textRefs.current.length - currentNode;
        const nodeY = animationData[index]?.relativeY
        if (value.lineY < nodeY)
        {
            setAnimationData((prevAnimationData) => {
                const newAnimationData = [...prevAnimationData];
                newAnimationData[index].visible = true;
                return newAnimationData;
            });
            setCurrentNode((prev) => prev + 1);
        }
    }

    // svg drawing
    function ScreenYToSVGY(screenY, svg) {
        const p = new DOMPoint()
        p.x = 0;
        p.y = screenY;
        return p.matrixTransform(svg.getScreenCTM()?.inverse()).y + 4.5;
    }

    const svgNodes = data.map((item, index) =>
    {
        if (!textRefs.current[index]) return "";
        
        if (!animationData[index]) 
        {   
            animationData[index] = {visible: false, relativeY: ScreenYToSVGY(textRefs.current[index].getBoundingClientRect().y, lineSVGRef.current)}
        }
        else
        {
            animationData[index].relativeY = ScreenYToSVGY(textRefs.current[index]?.getBoundingClientRect().y,  lineSVGRef.current);
        }
        
        const yValue = ScreenYToSVGY(textRefs.current[index].getBoundingClientRect().y, nodesSVGRef.current);

        if (item.type === "title")
        {
            return <circle key={index} style={animationData[index]?.visible ? {transform: "scale(1)"} : {}} cx="50%" cy={yValue} r="4" fill="white"/>
        }
        else
        {
            return <rect key={index} style={animationData[index]?.visible ? {transform: "translate(-50%) rotate(45deg) scale(1)"} : {}} y={yValue} x="50%" width="3.5" height="3.5" rx=".2" ry=".2" fill="white"/>
        }
    });

    // text drawing
    const experience = data.map((item, index) => 
    {
        const visibleStyle = 
        {
            opacity: 1,
            left: 0
        };
        
        const titleRect = textRefs.current[0]?.children[0].getBoundingClientRect();
        const subTitleRect = textRefs.current[0]?.children[1].getBoundingClientRect();
        const useSeparator = Math.abs(titleRect?.y - subTitleRect?.y) <= 1;

        if (item.type === "title")
        {
            return <div key={index} className="titleBlock" style={animationData[index]?.visible ? visibleStyle : {}} ref={(element) => textRefs.current[index] = element}>
                <h2 className={useSeparator ? "sectionTitle separator" : "sectionTitle noSeperator"}>{item.title}</h2>
                <h3 className="sectionSubtitle">{item.subTitle}</h3>
                <h3 className="duration">{item.duration}</h3>
            </div>
        }
        else
        {
            return <div key={index}  className="projectBlock" style={animationData[index]?.visible ? visibleStyle : {}} ref={(element) =>  textRefs.current[index] = element}>
                <h3 className="sectionTitle">{item.title}</h3>
                <p>{item.description}</p>
            </div>
        }
    });

    return <div className="timelineContent">              
        <svg viewBox="0 0 20 100" preserveAspectRatio="none" ref={lineSVGRef}>
            <animated.line x1="50%" y1={lineY} x2="50%" y2="95" stroke="white"  strokeWidth="1"/>
        </svg>
        <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMin meet" ref={nodesSVGRef}>
            {svgNodes}
        </svg>
        <div className="textContent">
            {experience}
        </div>
    </div>
}

export default Timeline