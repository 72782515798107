
import Skill from "./Skill";
import SmallSkill from "./SmallSkill";
import JobExperience from "./JobExperience";
import Education from "./Education";
import skillsData from "./Skills.json";
import smallSkillsData from "./SmallSkills.json"
import { useState } from "react";
import "./About.css";

function About()
{
    const [loaded, setLoaded] = useState(false);

    const skills = skillsData.map((item, index) =>
        <Skill key={index} name={item.name} logo={item.logo} experience={item.experience}  animationDelay={400 - index * 150} onLoad={OnLoadImage}/>
    );

    const smallSkills = smallSkillsData.map((item, index) =>
        <SmallSkill key={index} name={item.name} logo={item.logo} animationDelay={150 + index * 50} onLoad={OnLoadImage}/>
    );

    let loadedImages = 0;
    function OnLoadImage()
    {
        loadedImages++;
        if (loadedImages === skills.length + smallSkills.length) setLoaded(true);
    }

    return  <div className="aboutContainer">
                <div className="about" style={{display: loaded ? "" : "none"}}>
                    <div className="skills">
                        {skills}
                    </div>
                    <div className="otherSkillsContainer" style={{animationDelay: "150ms"}}>
                        <h2>Other Skills</h2>
                        <div className="otherSkills">
                            {smallSkills}
                        </div>
                    </div>
                    <div className="experience" style={{animationDelay: "200ms"}}>
                        <JobExperience/>
                        <Education/>
                    </div>
                </div>
            </div>
}

export default About;